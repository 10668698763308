import { Box } from '@coolblue-development/becky';

const ChatMessageLoader = (): JSX.Element => {
  return (
    <Box
      data-testid={'chatMessageLoader'}
      sx={{
        display: 'flex',
        gap: '5px',
        boxShadow: '0px 1px 2px 0px #285DAB33',
        width: '70px',
        border: '1px solid #DDDDDD',
        background: 'white',
        padding: '15px',
        marginTop: '10px',
        borderRadius: '5px'
      }}
    >
      <Box className="message-loader-dot"></Box>
      <Box className="message-loader-dot"></Box>
      <Box className="message-loader-dot"></Box>
    </Box>
  );
};

export default ChatMessageLoader;
