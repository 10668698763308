import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { IconButton } from '@coolblue-development/becky';
import { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

interface MarkdownButtonProps {
  formatType: 'bold' | 'italic';
  isActive: boolean;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const MarkdownButton = ({ formatType, isActive, Icon }: MarkdownButtonProps): JSX.Element => {
  const [editor] = useLexicalComposerContext();
  const [toggle, setToggle] = useState(isActive);

  useEffect(() => {
    setToggle(isActive);
  }, [isActive]);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setToggle(!toggle);
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);

    datadogLogs.logger.info(`Markdown used:`, { format: formatType });
  };

  return (
    <IconButton
      preset="text"
      onClick={onClick}
      iconSize="small"
      sx={{
        width: 'auto',
        height: 'auto',
        padding: '8px',
        ':hover': { background: '#F3F3F3' },
        background: toggle ? '#F3F3F3' : 'transparent'
      }}
    >
      <Icon />
    </IconButton>
  );
};

export default MarkdownButton;
