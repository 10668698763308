import React from 'react';
import { fetchContact } from '../../../../../utils/fetchContact';
import { Button } from '@coolblue-development/becky';

interface AcceptChatButtonProps {
  contactId: string;
}

const AcceptChatButton: React.FC<AcceptChatButtonProps> = ({ contactId }) => {
  const contact = fetchContact(contactId);

  const handleAcceptChat = (): void => {
    if (contact) {
      contact.accept();
    }
  };

  return (
    <Button data-testid="accept-chat-button" onClick={handleAcceptChat} preset="callToAction" size="small" fullWidth>
      Accept chat
    </Button>
  );
};

export default AcceptChatButton;
