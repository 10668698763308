import data, { EmojiMartData } from '@emoji-mart/data';

const allEmojis = Object.keys((data as EmojiMartData).emojis);

const selectedEmojis = new Set([
  'smiley',
  'slightly_smiling_face',
  'neutral_face',
  'slightly_frowning_face',
  'wink',
  'blue_heart',
  '+1',
  '-1',
  'muscle',
  'star',
  'tada',
  'fire',
  'sunny',
  'thunder_cloud_and_rain',
  'rain_cloud',
  'snow_cloud',
  'snowflake',
  'white_check_mark',
  'shopping_trolley',
  'iphone',
  'telephone_receiver',
  'paperclip',
  'flag-be',
  'de',
  'fr',
  'flag-nl'
]);

export const allowedEmojis = allEmojis.filter(emoji => !selectedEmojis.has(emoji));
