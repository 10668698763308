export const fetchContact = (contactId: string): connect.Contact | null => {
  if (!contactId) {
    return null;
  }

  let fetchedContact: connect.Contact | null = null;

  connect.agent(agent => {
    const contacts = agent.getContacts();
    fetchedContact = contacts.find(contact => contact.getContactId() === contactId) || null;
  });

  return fetchedContact;
};
