import { Box, Typography } from '@coolblue-development/becky';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ChatMessageProps {
  chatMessage: connect.ChatEventData;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ chatMessage }): JSX.Element | null => {
  const { Content: message, ParticipantRole: role, AbsoluteTime } = chatMessage;
  const isAgent = role !== 'CUSTOMER';

  const bubbleStyle = {
    width: 'fit-content',
    background: isAgent ? '#CCE9F9' : '#FFF',
    boxShadow: '0px 1px 2px 0px #285DAB33',
    border: isAgent ? 'none' : '1px solid #DDDDDD',
    borderRadius: '5px',
    padding: '15px',
    marginLeft: isAgent ? 'auto' : '',
    maxWidth: '60%'
  };

  const localTime = new Date(AbsoluteTime).toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit'
  });

  return message ? (
    <Box sx={{ marginTop: '10px', textAlign: isAgent ? 'right' : 'left' }} data-testid="chat-message">
      <Box
        style={bubbleStyle}
        sx={{ textAlign: 'left', wordBreak: 'break-word' }}
        data-testid={isAgent ? 'agent-bubble' : 'customer-bubble'}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ children }) => <p style={{ margin: 0 }}>{children}</p>,
            a: ({ ...props }) => <a {...props} target="_blank" />
          }}
        >
          {message}
        </ReactMarkdown>
      </Box>
      <Typography fontSize={11} color={'#999'}>
        {localTime}
      </Typography>
    </Box>
  ) : null;
};

export default ChatMessage;
