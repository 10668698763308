import { datadogLogs } from '@datadog/browser-logs';

export const endConversation = (contact: connect.Contact): void => {
  contact.getAgentConnection().destroy({
    success: function () {},
    failure: function (error) {
      const errorMessage = `Failed to close the conversation or contact: ${error}`;
      datadogLogs.logger.error(errorMessage);
    }
  });
};
