import React, { createContext, useContext, useEffect, useState } from 'react';
import syncFeatureFlags, { FeatureFlagInterface, featureFlagList } from './components/constants';
import { RecentCall } from './models/RecentCall';
import { Page } from './models/Page';
import { TelephonyWidgetContainer } from './models/TelephonyWidgetContainer';
import { useLocalStorageState } from './utils/hooks/useLocalStorageState';
import { EditorState } from 'lexical';

export type chatMessage = {
  Content?: string;
  ParticipantRole?: 'AGENT' | 'CUSTOMER' | 'SYSTEM' | undefined;
  AbsoluteTime: string;
  Id: string;
  ContactId?: string;
  attachment?: Blob;
};

export type ContactTypingInfo = {
  contactId: string;
  isTyping: boolean;
};

type AgentMessage = {
  selectedChat: string | null;
  state: EditorState;
};

export type DefaultCallActionState = { customer: boolean; thirdParty: boolean };
export const defaultCallActionStateValue = { customer: false, thirdParty: false };

export const AppContext = createContext<{
  agent: connect.Agent | undefined;
  setAgent: React.Dispatch<React.SetStateAction<connect.Agent | undefined>>;
  activePage: Page;
  setActivePage: React.Dispatch<React.SetStateAction<Page>>;
  chatMessages: connect.ChatEventData[];
  setChatMessages: React.Dispatch<React.SetStateAction<connect.ChatEventData[]>>;
  agentEmail: string | undefined;
  agentMessage: AgentMessage[];
  setAgentMessage: React.Dispatch<React.SetStateAction<AgentMessage[]>>;
  chatContacts: string[];
  setChatContacts: React.Dispatch<React.SetStateAction<string[]>>;
  agentVoiceConnection: connect.BaseConnection | null;
  setAgentVoiceConnection: React.Dispatch<React.SetStateAction<connect.BaseConnection | null>>;
  selectedChat: string | null;
  selectedRecentCall: RecentCall;
  setSelectedRecentCall: React.Dispatch<React.SetStateAction<RecentCall>>;
  setSelectedChat: React.Dispatch<React.SetStateAction<string | null>>;
  isTyping: ContactTypingInfo[];
  setIsTyping: React.Dispatch<React.SetStateAction<ContactTypingInfo[]>>;
  isTelephonyWidgetOpen: boolean;
  setIsTelephonyWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  featureFlags: FeatureFlagInterface[] | [];
  setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlagInterface[]>>;
  telephonyStatusBarOpen: boolean;
  setTelephonyStatusBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeTelephonyWidgetContainer: TelephonyWidgetContainer;
  setActiveTelephonyWidgetContainer: React.Dispatch<React.SetStateAction<TelephonyWidgetContainer>>;
  thirdPartyVoiceConnection: connect.VoiceConnection | null;
  setThirdPartyVoiceConnection: React.Dispatch<React.SetStateAction<connect.VoiceConnection | null>>;
  voiceContact: connect.Contact | null;
  setVoiceContact: React.Dispatch<React.SetStateAction<connect.Contact | null>>;
  agentState: connect.AgentState | null;
  setAgentState: React.Dispatch<React.SetStateAction<connect.AgentState | null>>;
  isAgentMute: boolean;
  setIsAgentMute: React.Dispatch<React.SetStateAction<boolean>>;
  isAgentHold: DefaultCallActionState;
  setIsAgentHold: React.Dispatch<React.SetStateAction<DefaultCallActionState>>;
  unreadMessages: { [key: string]: number };
  setUnreadMessages: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
}>({
  agent: undefined,
  setAgent: () => {},
  activePage: Page.TELEPHONY,
  selectedRecentCall: { phoneNumber: '', index: 0 },
  setSelectedRecentCall: () => {},
  setActivePage: () => {},
  chatMessages: [],
  setChatMessages: () => {},
  agentEmail: undefined,
  agentMessage: [],
  setAgentMessage: () => {},
  chatContacts: [],
  setChatContacts: () => {},
  agentVoiceConnection: null,
  setAgentVoiceConnection: () => {},
  selectedChat: '',
  setSelectedChat: () => {},
  isTyping: [],
  setIsTyping: () => {},
  isTelephonyWidgetOpen: false,
  setIsTelephonyWidgetOpen: () => {},
  featureFlags: [],
  setFeatureFlags: () => {},
  telephonyStatusBarOpen: false,
  setTelephonyStatusBarOpen: () => {},
  activeTelephonyWidgetContainer: TelephonyWidgetContainer.OUTBOUND,
  setActiveTelephonyWidgetContainer: () => {},
  thirdPartyVoiceConnection: null,
  setThirdPartyVoiceConnection: () => {},
  voiceContact: null,
  setVoiceContact: () => {},
  agentState: null,
  setAgentState: () => {},
  isAgentMute: false,
  setIsAgentMute: () => {},
  isAgentHold: defaultCallActionStateValue,
  setIsAgentHold: () => {},
  unreadMessages: {},
  setUnreadMessages: () => {}
});

export const useAppContext = (): React.ContextType<typeof AppContext> => useContext(AppContext);

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [agent, setAgent] = useState<connect.Agent>();
  const [activePage, setActivePage] = useState<Page>(Page.TELEPHONY);
  const [selectedRecentCall, setSelectedRecentCall] = useState<RecentCall>({ phoneNumber: '', index: 0 });
  const [chatMessages, setChatMessages] = useState<connect.ChatEventData[]>([]);
  const [agentMessage, setAgentMessage] = useState<AgentMessage[]>([]);
  const [chatContacts, setChatContacts] = useState<string[]>([]);
  const [agentVoiceConnection, setAgentVoiceConnection] = useState<connect.BaseConnection | null>(null);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [isTyping, setIsTyping] = useState<ContactTypingInfo[]>([]);
  const [isTelephonyWidgetOpen, setIsTelephonyWidgetOpen] = useState<boolean>(false);
  const [agentEmail, setAgentEmail] = useState<string>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagInterface[] | []>([]);
  const [telephonyStatusBarOpen, setTelephonyStatusBarOpen] = useState<boolean>(false);
  const [activeTelephonyWidgetContainer, setActiveTelephonyWidgetContainer] = useState<TelephonyWidgetContainer>(
    TelephonyWidgetContainer.OUTBOUND
  );
  const [thirdPartyVoiceConnection, setThirdPartyVoiceConnection] = useState<connect.VoiceConnection | null>(null);
  const [voiceContact, setVoiceContact] = useState<connect.Contact | null>(null);
  const [agentState, setAgentState] = useState<connect.AgentState | null>(null);
  const [isAgentMute, setIsAgentMute] = useState<boolean>(false);
  const [isAgentHold, setIsAgentHold] = useState<DefaultCallActionState>(defaultCallActionStateValue);
  const [unreadMessages, setUnreadMessages] = useState<{ [key: string]: number }>({});

  // Get the agent's email for Cassie's websocket connection
  useEffect(() => {
    connect.agent(function (agent) {
      const email = agent.getConfiguration().username;
      setAgentEmail(email);
    });
  }, []);

  // Load feature flags from local storage
  const [localFeatureFlags, setLocalFeatureFlags] = useLocalStorageState<FeatureFlagInterface[]>(
    'featureFlags',
    featureFlagList
  );
  useEffect(() => {
    setFeatureFlags(localFeatureFlags);
  }, [localFeatureFlags]);

  // Sync the user's feature flags with the up-to-date hardcoded list
  useEffect(() => {
    const synchronisedFeatureFlags = syncFeatureFlags(localFeatureFlags);
    if (JSON.stringify(localFeatureFlags) !== JSON.stringify(synchronisedFeatureFlags)) {
      setLocalFeatureFlags(synchronisedFeatureFlags);
    }
  }, [localFeatureFlags, setLocalFeatureFlags]);

  return (
    <AppContext.Provider
      value={{
        agent,
        setAgent,
        activePage,
        setActivePage,
        selectedRecentCall,
        setSelectedRecentCall,
        chatMessages,
        setChatMessages,
        agentEmail,
        agentMessage,
        setAgentMessage,
        chatContacts,
        setChatContacts,
        agentVoiceConnection,
        setAgentVoiceConnection,
        selectedChat,
        setSelectedChat,
        isTyping,
        setIsTyping,
        isTelephonyWidgetOpen,
        setIsTelephonyWidgetOpen,
        telephonyStatusBarOpen,
        setTelephonyStatusBarOpen,
        featureFlags,
        activeTelephonyWidgetContainer,
        setActiveTelephonyWidgetContainer,
        thirdPartyVoiceConnection,
        setThirdPartyVoiceConnection,
        voiceContact,
        setVoiceContact,
        agentState,
        setAgentState,
        isAgentMute,
        setIsAgentMute,
        isAgentHold,
        setIsAgentHold,
        unreadMessages,
        setUnreadMessages,
        setFeatureFlags: setLocalFeatureFlags
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
