import React from 'react';
import { fetchContact } from '../../../../../utils/fetchContact';
import { Button } from '@coolblue-development/becky';
import { datadogLogs } from '@datadog/browser-logs';

interface EndChatButtonProps {
  contactId: string;
}

const EndChatButton: React.FC<EndChatButtonProps> = ({ contactId }) => {
  const contact = fetchContact(contactId);

  const handleEndChat = (): void => {
    if (contact) {
      contact.clear({
        success: function () {},
        failure: function () {
          datadogLogs.logger.error('Error occured while ending the chat session');
        }
      });
    }
  };

  return (
    <Button
      data-testid="end-chat-button"
      sx={{
        background: '#E50000',
        boxShadow: 'inset 0 -2px 0 #AA2222',
        ':hover': { background: '#BE0000', boxShadow: 'inset 0 -2px 0 #680B0F' }
      }}
      onClick={handleEndChat}
      color="error"
      size="small"
      fullWidth
    >
      Close chat
    </Button>
  );
};

export default EndChatButton;
