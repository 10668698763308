import { Page } from '../../../models/Page';
import { Box } from '@coolblue-development/becky';
import { useAppContext } from '../../../AppContext';
import Settings from './Settings/Settings';
import TelephonyPage from './Telephony/Page/TelephonyPage';
import CcpPanel from './CcpPanel';
import { useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import ChatPage from './Chat/Body/ChatPage';
import TelephonyWidgetContainer from './Telephony/Widget/TelephonyWidgetContainer';

interface PageContainerProps {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PageContainer: React.FC<PageContainerProps> = ({ containerRef }) => {
  const { activePage, isTelephonyWidgetOpen } = useAppContext();

  useEffect(() => {
    datadogLogs.logger.info(`Active page:`, { activePage: activePage });
  }, [activePage]);

  return (
    <Box sx={{ width: '100%', margin: '10px' }}>
      {activePage === Page.SETTINGS && <Settings />}
      {activePage === Page.CHAT && <ChatPage />}
      {activePage === Page.TELEPHONY && <TelephonyPage />}
      {containerRef && <CcpPanel containerRef={containerRef} />}
      {isTelephonyWidgetOpen && <TelephonyWidgetContainer />}
    </Box>
  );
};

export default PageContainer;
