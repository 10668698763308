import { Box } from '@coolblue-development/becky';
import SideMenu from './SideMenu';

const SidePanel = (): JSX.Element => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: '#FFF',
        boxShadow: '0px 4px 16px 0px #246CB429',
        minWidth: '280px',
        position: 'relative',
        margin: '10px',
        overflow: 'hidden'
      }}
    >
      <SideMenu />
    </Box>
  );
};

export default SidePanel;
