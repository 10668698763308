import React, { useState, useEffect, useRef } from 'react';
import QuickReplies from './QuickReplies';
import { Box } from '@mui/system';
import { datadogLogs } from '@datadog/browser-logs';
import QuickRepliesCountrySelector from './QuickRepliesCountrySelector';
import { Country } from '../../../../../models/Country';
import { Button } from '@mui/material';
import { useAppContext } from '../../../../../AppContext';

const QuickRepliesContainer = (): JSX.Element => {
  const { agent, chatContacts } = useAppContext();
  const [showReplies, setShowReplies] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [quickReplies, setQuickReplies] = useState<connect.QuickResponse[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
  const agentName = agent?.getName();

  const toggleReplies = (): void => {
    setShowReplies(!showReplies);
  };

  useEffect(() => {
    const namePlaceholderRegex = /\[NAAM\]/g;

    if (chatContacts.length === 0) {
      return;
    }

    connect.QuickResponses.searchQuickResponses({ query: selectedCountryCode ? `${selectedCountryCode}_` : '' })
      .then(response => {
        const updatedQuickReplies = response.results.map(reply => {
          if (reply.contents.plainText && agentName) {
            reply.contents.plainText.content = reply.contents.plainText.content.replace(
              namePlaceholderRegex,
              agentName
            );
          }
          return reply;
        });

        setQuickReplies(updatedQuickReplies);
      })
      .catch((error: Error) => {
        datadogLogs.logger.error(`Unable to retrieve Quick Replies`, { quickReplies: true }, error);
      });
  }, [selectedCountryCode, chatContacts, agentName]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowReplies(false);
      }
    };

    if (showReplies) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showReplies]);

  const handleCountrySelect = React.useCallback((country: Country): void => {
    setSelectedCountryCode(country.country);
  }, []);

  return (
    <Box ref={containerRef}>
      <Box sx={{ display: showReplies ? 'block' : 'none' }}>
        <QuickReplies setShowReplies={setShowReplies} quickReplies={quickReplies} />
      </Box>
      <QuickRepliesCountrySelector onCountrySelect={handleCountrySelect} />
      <Button
        data-testid="quick-replies-button"
        sx={{
          height: '48px',
          padding: '0 16px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          border: '1px solid #DDDDDD',
          background: showReplies ? '#F2F7FC' : 'inherit',
          borderColor: showReplies ? '#0090E3' : '#DDDDDD'
        }}
        disabled={chatContacts.length === 0}
        onClick={toggleReplies}
      >
        Quick Replies
      </Button>
    </Box>
  );
};

export default QuickRepliesContainer;
