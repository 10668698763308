import React, { useEffect, useState } from 'react';
import { Label, Typography } from '@coolblue-development/becky';
import TimerIcon from '../../../Icons/TimerIcon';
import { formatDuration } from '../../../../../utils/formatDuration';
import { fetchContact } from '../../../../../utils/fetchContact';

interface TimerProps {
  contactId: string;
  isAfterCallWork?: boolean;
}

const TimerNew: React.FC<TimerProps> = ({ contactId, isAfterCallWork }) => {
  const contact = fetchContact(contactId);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!contact) {
      return;
    }

    setDuration(contact.getStateDuration());
  }, [contact]);

  useEffect(() => {
    const timerId = setInterval(() => setDuration(prevDuration => prevDuration + 1000), 1000);
    return () => clearInterval(timerId);
  }, [duration]);

  return (
    <Label data-testid="timer" color={isAfterCallWork ? 'orange' : 'green'} variant="subtle">
      <TimerIcon color={isAfterCallWork ? '#FF6600' : '#008500'} />
      <Typography variant="body1" sx={{ marginLeft: '5px' }}>
        {formatDuration(duration)}
      </Typography>
    </Label>
  );
};

export default TimerNew;
