import { Button } from '@coolblue-development/becky';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../config';

type ChatDetails = {
  contactId: string;
  participantId: string;
  participantToken: string;
};

type ChatApiResponse = {
  data: {
    startChatResult: ChatDetails;
    sessionType: string;
    region: string;
  };
};

type BearerToken = {
  access_token: string;
};

const GenerateChatButton = (): JSX.Element => {
  const [chatDetails, setChatDetails] = useState<ChatDetails | null>(null);
  const { chatApiAuthUrl, chatApiAuthUsername, chatApiAuthPassword, chatApiUrl } = config;

  const getToken = async (): Promise<void> => {
    const credentials = btoa(`${chatApiAuthUsername}:${chatApiAuthPassword}`);

    try {
      const response = await fetch(chatApiAuthUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${credentials}`
        },
        body: new URLSearchParams({ grant_type: 'client_credentials' }).toString()
      });

      const data = (await response.json()) as BearerToken;
      if (!response.ok) throw new Error('Failed to fetch token');

      await sendChatRequest(data.access_token);
    } catch (err) {
      console.error('Failed to connect chat session:', err);
    }
  };

  const sendChatRequest = async (bearerToken: string): Promise<void> => {
    const chatApiUrlWithId = `${chatApiUrl ?? ''}${uuidv4()}`;
    const DisplayName = 'some-name';
    const CallGroupId = '1001';

    try {
      const response = await fetch(chatApiUrlWithId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`
        },
        body: JSON.stringify({ DisplayName: DisplayName, CallGroupId: CallGroupId })
      });

      const data = (await response.json()) as ChatApiResponse;
      if (!response.ok) throw new Error('Failed to fetch chats');

      const { contactId, participantId, participantToken } = data.data.startChatResult;
      setChatDetails({ contactId, participantId, participantToken });
    } catch (err) {
      console.error('Failed to connect chat session:', err);
    }
  };

  useEffect(() => {
    const region = 'eu-central-1';
    connect.ChatSession.setGlobalConfig({ region: region });

    const connectChatSession = async (): Promise<void> => {
      if (!chatDetails) return;

      try {
        const customerChatSession = connect.ChatSession.create({
          chatDetails: {
            contactId: chatDetails.contactId,
            participantId: chatDetails.participantId,
            participantToken: chatDetails.participantToken
          },
          type: 'CUSTOMER'
        });

        await customerChatSession.connect();
      } catch (err) {
        console.error('Failed to connect chat session:', err);
      }
    };

    void connectChatSession();
  }, [chatDetails]);

  return (
    <Button variant="contained" color="primary" onClick={getToken}>
      Generate Chat 💬
    </Button>
  );
};

export default GenerateChatButton;
