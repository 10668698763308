import { Box, IconButton } from '@coolblue-development/becky';
import { ReactComponent as Link } from '../../../../../assets/link.svg';
import { useState } from 'react';
import LinkInputForm from './LinkInputForm';

const LinkButton = (): JSX.Element => {
  const [toggle, setToggle] = useState(false);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setToggle(!toggle);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        preset="text"
        onClick={onClick}
        iconSize="small"
        sx={{
          width: 'auto',
          height: 'auto',
          padding: '8px',
          ':hover': { background: '#F3F3F3' },
          background: toggle ? '#F3F3F3' : 'transparent'
        }}
      >
        <Link />
      </IconButton>
      {toggle && <LinkInputForm />}
    </Box>
  );
};

export default LinkButton;
