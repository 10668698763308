import { Box } from '@coolblue-development/becky';
import Editor from './Editor/Editor';

const ChatInput = (): JSX.Element => {
  return (
    <Box
      sx={{
        background: '#FFF',
        width: '100%',
        padding: '15px',
        borderTop: '#DDDDDD 1px solid',
        boxShadow: '0px -4px 16px 0px #00000014',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px'
      }}
    >
      <Editor />
    </Box>
  );
};

export default ChatInput;
