import { Box } from '@coolblue-development/becky';
import { useAppContext } from '../../../../../AppContext';
import TelephonyTopPanel from './TelephonyTopPanel';

const TelephonyPage = (): JSX.Element => {
  const { selectedRecentCall } = useAppContext();

  return (
    <Box>
      <TelephonyTopPanel selectedRecentCall={selectedRecentCall} />
    </Box>
  );
};

export default TelephonyPage;
