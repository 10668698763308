import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Typography } from '@coolblue-development/becky';
import { useAppContext } from '../../../../../AppContext';
import { fetchContact } from '../../../../../utils/fetchContact';

const DigitalAssistentSummary = (): JSX.Element | null => {
  const { selectedChat } = useAppContext();
  const [summary, setSummary] = useState<string>('');

  useEffect(() => {
    if (!selectedChat) {
      setSummary('');
      return;
    }

    const contact = fetchContact(selectedChat);
    if (!contact) return;

    const chatbotSummary = contact.getAttributes().chatbot_summary?.value;

    setSummary(chatbotSummary ?? '');
  }, [selectedChat]);

  return summary ? (
    <>
      <Box
        width="100%"
        data-testid="summary"
        sx={{
          border: '1px solid #DDDDDD',
          boxShadow: '0px 1px 2px 0px #285DAB33',
          padding: '16px',
          borderRadius: '5px'
        }}
      >
        <Typography variant="h2">Summary Digital Assistent</Typography>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{summary}</ReactMarkdown>
      </Box>
    </>
  ) : null;
};

export default DigitalAssistentSummary;
