import { Box, Label, Tab, TabPanel, Tabs, Typography } from '@coolblue-development/becky';
import { useEffect, useState } from 'react';
import { Page } from '../../../models/Page';
import { useAppContext } from '../../../AppContext';
import { IsB2bAgent } from '../../../utils/isB2bAgent';
import { calculateTotalUnread } from '../../../utils/calculateTotalUnread';

const Channels = (): JSX.Element => {
  const { setActivePage, activePage, selectedChat, unreadMessages } = useAppContext();

  const menuItems: Page[] = [Page.TELEPHONY, ...(!IsB2bAgent() ? [Page.CHAT] : [])];
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleMenuItemClick = (item: Page): void => {
    setActivePage(item);
  };

  useEffect(() => {
    let totalUnreadCount = 0;
    totalUnreadCount = calculateTotalUnread(unreadMessages);

    setTotalCount(totalUnreadCount);
  }, [selectedChat, unreadMessages]);

  const activeIndex = menuItems.findIndex(item => item === activePage);

  return (
    <Tabs value={activeIndex} variant="fullWidth" sx={{ padding: '0px 20px' }}>
      {menuItems.map(item => {
        const tabLabel = (
          <Typography
            sx={{
              color: '#000',
              lineHeight: 1.25
            }}
            variant={activePage === item ? 'h5' : 'body1'}
          >
            {item}
          </Typography>
        );

        return (
          <Box key={item} sx={{ display: 'flex', alignItems: 'center', marginTop: '11px' }}>
            <Tab
              key={item}
              onClick={() => handleMenuItemClick(item)}
              label={tabLabel}
              sx={{ borderBottom: activePage === item ? '3px solid #0090E3' : 'none', opacity: '1' }}
            />

            <TabPanel value={activeIndex} index={activeIndex}>
              {item === Page.CHAT && totalCount > 0 && (
                <Label data-testid="unreadMessageCount" color="orange" sx={{ marginLeft: '10px' }}>
                  {totalCount}
                </Label>
              )}
            </TabPanel>
          </Box>
        );
      })}
    </Tabs>
  );
};

export default Channels;
