import { Box, IconButton, InputAdornment, TextField } from '@coolblue-development/becky';
import React, { useState } from 'react';
import { ReactComponent as Link } from '../../../../../assets/link.svg';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';

const LinkInputForm = (): JSX.Element => {
  const [editor] = useLexicalComposerContext();
  const [linkValue, setLinkValue] = useState('');

  const linkToText = (): void => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: linkValue });
  };

  return (
    <Box>
      <TextField
        onChange={(event): void => {
          setLinkValue(event.target.value);
        }}
        value={linkValue}
        sx={{
          marginLeft: '10px',
          height: '30px',
          width: '300px',
          '& .MuiOutlinedInput-root': { paddingRight: '1.4rem' }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {linkValue.length > 0 && (
                <IconButton sx={{ width: '30px' }} type="button" preset="text" onClick={linkToText}>
                  <Link />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default LinkInputForm;
