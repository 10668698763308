import { Button } from '@coolblue-development/becky';
import React from 'react';
import { useAppContext } from '../../../../../AppContext';
import { fetchContact } from '../../../../../utils/fetchContact';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { getChatSession } from '../../../../../utils/getChatSession';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { $getRoot } from 'lexical';

const SendButton = (): JSX.Element => {
  const { selectedChat } = useAppContext();
  const [editor] = useLexicalComposerContext();

  const sendMessage = async (): Promise<void> => {
    if (!selectedChat) return;

    const contact = fetchContact(selectedChat);
    if (!contact) return;

    const chatSession = await getChatSession(contact);
    if (!chatSession) return;

    editor.read(() => {
      const message = $convertToMarkdownString(TRANSFORMERS);
      void chatSession.sendMessage({
        contentType: 'text/markdown',
        message: message
      });
    });

    editor.update(() => $getRoot().clear());
  };

  return (
    <Button type="submit" data-testid="send-button" onClick={sendMessage}>
      Send
    </Button>
  );
};

export default SendButton;
