import React, { useEffect, useState } from 'react';
import { Label, Typography } from '@coolblue-development/becky';
import { formatDuration } from '../../../../../utils/formatDuration';
import GlasshourIcon from '../../../Icons/GlasshourIcon';
import { useAppContext } from '../../../../../AppContext';

interface LastChatTimerNewProps {
  contactId: string;
}

const LastChatTimerNew: React.FC<LastChatTimerNewProps> = ({ contactId }) => {
  const { chatMessages } = useAppContext();
  const [duration, setDuration] = useState(0);
  const lastMessage = chatMessages.filter(msg => msg.ContactId === contactId);
  const lastMessageTime = lastMessage.length > 0 ? lastMessage[lastMessage.length - 1].AbsoluteTime : null;

  useEffect(() => {
    if (!lastMessageTime) return;

    const startTime = new Date(lastMessageTime).getTime();
    const timerId = setInterval(() => setDuration(Date.now() - startTime), 1000);

    return () => clearInterval(timerId);
  }, [lastMessageTime]);

  return (
    <Label color="blue" variant="subtle" sx={{ marginLeft: '5px' }}>
      <GlasshourIcon color="#1E4680" />
      <Typography variant="body1" sx={{ marginLeft: '5px' }}>
        {formatDuration(duration)}
      </Typography>
    </Label>
  );
};

export default LastChatTimerNew;
