import { Country } from '../models/Country';

export const countrySelectorUtil = (countries: Country[], isQuickReply?: boolean): Country => {
  let defaultCountry = countries.find(country => country.code === '+31');
  connect.agent(agent => {
    const username = agent.getConfiguration().username;
    const country = username.slice(-2);

    if (country === 'be' && !isQuickReply) {
      defaultCountry = countries.find(country => country.code === '+32');
    } else if (country === 'de') {
      defaultCountry = countries.find(country => country.code === '+49');
    }
  });

  return defaultCountry ?? countries[0];
};
