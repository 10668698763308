const getEnvironmentVariable = (environmentVariable: string, isOptional = false): string => {
  const value = process.env[environmentVariable];

  if (!value && !isOptional) {
    throw new Error(`Couldn't find environment variable: ${environmentVariable}`);
  }

  return value || '';
};

const config = {
  ccpInstanceURL: getEnvironmentVariable('REACT_APP_CCP_INSTANCE_URL'),
  cppSamURL: getEnvironmentVariable('REACT_APP_SAML_URL'),
  cassieWebsocketURL: getEnvironmentVariable('REACT_APP_CASSIE_WS_URL'),
  cassieWebsocketProtocol: getEnvironmentVariable('REACT_APP_CASSIE_PROTOCOL'),
  title: getEnvironmentVariable('REACT_APP_TITLE'),
  region: getEnvironmentVariable('REACT_APP_REGION'),
  environment: getEnvironmentVariable('REACT_APP_ENV', true),
  chatApiAuthUrl: getEnvironmentVariable('REACT_APP_CHAT_AUTH_URL', true),
  chatApiUrl: getEnvironmentVariable('REACT_APP_CHAT_API_URL', true),
  chatApiAuthUsername: getEnvironmentVariable('REACT_APP_CHAT_AUTH_USERNAME', true),
  chatApiAuthPassword: getEnvironmentVariable('REACT_APP_CHAT_AUTH_PASSWORD', true)
};

export default config;
