import { Box, Typography } from '@coolblue-development/becky';
import Channels from './Channels';
import { useAppContext } from '../../../AppContext';
import ChatSidePanel from '../Pages/Chat/SidePanel/ChatSidePanel';
import { Page } from '../../../models/Page';
import TelephonySidePanel from '../Pages/Telephony/Page/TelephonySidePanel';
import { useCallback } from 'react';
import TelephonyWidgetButton from './TelephonyWidgetButton';
const SideMenu = (): JSX.Element => {
  const { activePage, setSelectedRecentCall } = useAppContext();

  const handleSelectedRecentCall = useCallback(
    (phoneNumber: string, index: number): void => {
      setSelectedRecentCall({ phoneNumber, index });
    },
    [setSelectedRecentCall]
  );

  return (
    <>
      <Channels />
      <Box
        sx={{
          overflow: 'auto',
          height: 'calc(100% - 50px)',
          padding: '12px 16px'
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: '12px' }}>
          Inbox
        </Typography>
        {activePage === Page.CHAT && <ChatSidePanel />}
        {activePage === Page.TELEPHONY && <TelephonySidePanel handleSelectedRecentCall={handleSelectedRecentCall} />}
        <Box sx={{ position: 'absolute', bottom: '16px', left: '16px' }}>
          <TelephonyWidgetButton />
        </Box>
      </Box>
    </>
  );
};

export default SideMenu;
