import React from 'react';
import { fetchContact } from '../../../../../utils/fetchContact';
import { useAppContext } from '../../../../../AppContext';
import { Button } from '@coolblue-development/becky';
import { endConversation } from '../../../../../utils/endConversation';

interface CloseConversationProps {
  icon: JSX.Element;
  label: string;
  disabled?: boolean;
}

const CloseConversationButton: React.FC<CloseConversationProps> = ({ icon, label, disabled }): JSX.Element => {
  const { selectedChat } = useAppContext();

  const handleCloseConversation = async (): Promise<void> => {
    const contact = selectedChat ? fetchContact(selectedChat) : null;

    if (contact) {
      endConversation(contact);
    }
  };

  return (
    <Button
      sx={{
        background: '#E50000',
        boxShadow: 'inset 0 -2px 0 #AA2222',
        ':hover': {
          background: '#BE0000',
          boxShadow: 'inset 0 -2px 0 #680B0F'
        },
        ':disabled': { background: '#cc9494' }
      }}
      startIcon={icon}
      onClick={handleCloseConversation}
      size="small"
      disabled={disabled}
      preset="callToAction"
      data-testid="close-conversation-button"
    >
      {label}
    </Button>
  );
};

export default CloseConversationButton;
