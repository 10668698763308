import { Box } from '@mui/material';
import React, { useLayoutEffect, useRef } from 'react';
import DigitalAssistentSummary from './DigitalAssistentSummary';
import { useAppContext } from '../../../../../AppContext';
import ChatMessage from './ChatMessage';
import ChatMessageLoader from './ChatMessageLoader';

const ChatBody = (): JSX.Element => {
  const { selectedChat, chatMessages, isTyping } = useAppContext();
  const chatBodyRef = useRef<HTMLDivElement | null>(null);
  const filteredChatMessages = chatMessages.filter(message => message.ContactId === selectedChat);

  useLayoutEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [filteredChatMessages]);

  return (
    <Box
      sx={{
        overflow: 'auto',
        height: '100%',
        position: 'relative'
      }}
      data-testid="chat-body-new"
      ref={chatBodyRef}
    >
      <Box sx={{ overflow: 'auto', padding: '15px' }}>
        <DigitalAssistentSummary />
        {filteredChatMessages.map((item, index) => (
          <ChatMessage key={index} chatMessage={item} />
        ))}

        {isTyping.map(
          typingEvent =>
            typingEvent.contactId === selectedChat &&
            typingEvent.isTyping && <ChatMessageLoader key={typingEvent.contactId} />
        )}
      </Box>
    </Box>
  );
};

export default ChatBody;
