import { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@coolblue-development/becky';
import data, { EmojiMartData } from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { ReactComponent as Emoticon } from '../../../../../assets/emoticon.svg';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { datadogLogs } from '@datadog/browser-logs';
import { allowedEmojis } from '../../../Util/EmojiList';

type Emoji = {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string[];
};

const EmojiContainer = (): JSX.Element => {
  const [editor] = useLexicalComposerContext();
  const [toggle, setToggle] = useState(false);
  const containerRef = useRef<HTMLButtonElement>(null);

  const onEmojiClick = (emojiObject: Emoji): void => {
    editor.update(() => {
      const root = $getRoot();

      root.selectEnd().insertText(emojiObject.native);

      datadogLogs.logger.info(`Emoji used:`, { type: emojiObject.native });
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setToggle(false);
      }
    };

    if (toggle) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggle]);

  return (
    <IconButton
      preset="text"
      iconSize="small"
      sx={{
        width: 'auto',
        height: 'auto',
        padding: '8px',
        marginLeft: '8px',
        cursor: 'pointer',
        '&:hover': { background: '#F3F3F3' }
      }}
      ref={containerRef}
      onClick={e => {
        setToggle(!toggle);
      }}
    >
      <Emoticon style={{ width: '1.5rem', height: '1.5rem' }} />

      {toggle && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '40px',
            left: '0px',
            boxShadow: '0px 4px 4px 0px #00000040',
            zIndex: 2,
            borderRadius: '8px'
          }}
          onClick={e => e.stopPropagation()}
        >
          <Picker
            data={data as EmojiMartData}
            exceptEmojis={allowedEmojis} // due to API limitations we are doing the inverted logic: filtering the data with only the ones we want to show
            onEmojiSelect={onEmojiClick}
            skinTonePosition="none"
            theme="light"
          />
        </Box>
      )}
    </IconButton>
  );
};

export default EmojiContainer;
