import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  EditorState,
  KEY_ENTER_COMMAND,
  RangeSelection
} from 'lexical';
import React, { useEffect, useRef } from 'react';
import { useAppContext } from '../../../../../../AppContext';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { ListNode } from '@lexical/list';
import { fetchContact } from '../../../../../../utils/fetchContact';
import { getChatSession } from '../../../../../../utils/getChatSession';
import { TYPING_EVENT_DURATION } from '../../../../../../utils/constants';

export function EditorEventPlugin({
  onChange,
  setFormat,
  setActiveListType
}: {
  onChange: (editorState: EditorState) => void;
  setFormat: React.Dispatch<React.SetStateAction<[boolean, boolean]>>;
  setActiveListType: React.Dispatch<React.SetStateAction<'ordered' | 'unordered' | null>>;
}): null {
  const [editor] = useLexicalComposerContext();
  const { selectedChat, setAgentMessage, agentMessage } = useAppContext();
  const isTypingRef = useRef(false);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
      editorState.read(() => {
        const selection = $getSelection() as RangeSelection;

        const root = $getRoot();
        const children = root.getChildren();
        const lastNode = children.at(-1);

        if (selection.hasFormat('bold') || selection.hasFormat('italic')) {
          setFormat([selection.hasFormat('bold'), selection.hasFormat('italic')]);
        } else {
          setFormat([false, false]);
        }

        if (!(lastNode instanceof ListNode)) {
          setActiveListType(null);
        }

        const editorState = editor.getEditorState();

        setAgentMessage(prevAgentMessages => {
          const filteredMessages = prevAgentMessages.filter(msg => msg.selectedChat !== selectedChat);
          return [...filteredMessages, { selectedChat, state: editorState }];
        });
      });
    });
  }, [editor, onChange, setAgentMessage, selectedChat, setActiveListType, setFormat]);

  useEffect(() => {
    if (selectedChat) {
      editor.update(() => {
        const agentMessageForChat = agentMessage.find(msg => msg.selectedChat === selectedChat);
        if (agentMessageForChat) {
          editor.setEditorState(agentMessageForChat.state);
        } else {
          const root = $getRoot();
          root.clear();
          const paragraph = $createParagraphNode();
          root.append(paragraph);
          paragraph.select();
        }
      });
    }
    // agentMessage should NOT trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, editor]);

  useEffect(() => {
    if (!selectedChat) return;

    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (payload: KeyboardEvent) => {
        const root = $getRoot();
        const children = root.getChildren();
        const lastNode = children.at(-1);

        if (payload.key === 'Enter' && !payload.shiftKey && !(lastNode instanceof ListNode)) {
          void (async () => {
            const contact = fetchContact(selectedChat);
            if (!contact) return;

            const chatSession = await getChatSession(contact);
            if (!chatSession) return;

            editor.read(() => {
              const message = $convertToMarkdownString(TRANSFORMERS);
              void chatSession.sendMessage({
                contentType: 'text/markdown',
                message: message
              });
            });

            editor.update(() => $getRoot().clear());
          })();

          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, selectedChat]);

  useEffect(() => {
    const handleInputTyping = async (): Promise<void> => {
      if (!selectedChat) return;

      const contact = fetchContact(selectedChat);
      if (!contact) return;

      if (!isTypingRef.current) {
        const chatSession = await getChatSession(contact);
        if (!chatSession) return;

        void chatSession?.sendEvent({
          contentType: 'application/vnd.amazonaws.connect.event.typing'
        });

        isTypingRef.current = true;

        setTimeout(() => {
          isTypingRef.current = false;
        }, TYPING_EVENT_DURATION);
      }
    };

    editor.registerTextContentListener(() => {
      void handleInputTyping();
    });
  }, [editor, selectedChat]);

  return null;
}
