import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { ContactTypingInfo, useAppContext } from '../../AppContext';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import AuthenticationExpired from '../AuthenticationExpired';
import Contact from '../../models/Contact';
import Header from './Header/Header';
import { Page } from '../../models/Page';
import PageContainer from './Pages/PageContainer';
import SidePanel from './Sidepanel/SidePanel';
import TelephonyStatusBar from './TelephonyStatusBar/TelephonyStatusBar';
import { addRecentCallsToLocalStorage } from '../../utils/recentCallsUtil';
import { getChatSession } from '../../utils/getChatSession';
import { ParticipantRole } from '../../utils/participantRole';

enum BackdropState {
  None = 0,
  Loading = 1,
  AuthenticationExpired = 2
}

interface CatoContainerProps {
  instanceURL: string;
  samURL: string;
  onViewContact: (contact: Contact) => void;
  region: string;
}

function CatoContainer({ instanceURL, samURL, onViewContact, region }: CatoContainerProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [backdropState, setBackdropState] = useState(BackdropState.Loading);
  const {
    setAgent,
    activePage,
    setActivePage,
    setAgentVoiceConnection,
    selectedChat,
    setSelectedChat,
    setIsTelephonyWidgetOpen,
    telephonyStatusBarOpen,
    setTelephonyStatusBarOpen,
    setThirdPartyVoiceConnection,
    setVoiceContact,
    setAgentState,
    setChatContacts,
    chatContacts,
    setChatMessages,
    setUnreadMessages,
    setIsTyping
  } = useAppContext();
  const isInitializedRef = useRef(false);
  const selectedChatRef = useRef(selectedChat);
  const chatNotificationAudio = useMemo(
    () => new Audio(`${process.env.REACT_APP_CATO_URL ?? ''}/chatNotification.mp3`),
    []
  );

  useEffect(() => {
    if (selectedChat) {
      onViewContact({ contactId: selectedChat });
      connect.core.viewContact(selectedChat);
      selectedChatRef.current = selectedChat;
    }
  }, [onViewContact, selectedChat]);

  useEffect(() => {
    if (activePage !== Page.CHAT) {
      setSelectedChat(null);
    }
  }, [activePage, setSelectedChat]);

  useEffect(() => {
    if (isInitializedRef.current) {
      return;
    }
    isInitializedRef.current = true;

    connect.agent(agent => {
      const activeContacts = agent.getContacts();
      const contactIds = activeContacts.map(contact => contact.getContactId());
      setChatContacts(contactIds);
    });

    connect.contact(contact => {
      contact.onConnected(async () => {
        const chatSession = await getChatSession(contact);
        if (!chatSession) {
          return;
        }

        chatSession.onMessage(async (event: connect.ChatMessageEvent) => {
          const message = event.data as connect.ChatTranscriptItem;
          setChatMessages(prevChatMessages => [...prevChatMessages, message]);

          if (message.ParticipantRole === ParticipantRole.CUSTOMER && contact.contactId !== selectedChatRef.current) {
            setUnreadMessages(prev => ({
              ...prev,
              [contact.contactId]: (prev[contact.contactId] || 0) + 1
            }));
          }

          if (document.hidden) {
            void chatNotificationAudio.play();
          }
        });

        chatSession.onTyping(async (event: connect.ChatTypingEvent) => {
          if (event.data.ParticipantRole === ParticipantRole.CUSTOMER) {
            const newTypingEvent: ContactTypingInfo = {
              contactId: contact.contactId,
              isTyping: true
            };

            setIsTyping(prevTypingEvents => {
              const filteredEvents = prevTypingEvents.filter(item => item.contactId !== contact.contactId);
              return [...filteredEvents, newTypingEvent];
            });

            setTimeout(() => {
              setIsTyping(prevTypingEvents => {
                return prevTypingEvents.filter(item => item.contactId !== contact.contactId);
              });
            }, 12000);
          }
        });

        const sessionTranscript = await chatSession.getTranscript({});
        const transcriptResponse = sessionTranscript.data?.Transcript;

        if (transcriptResponse) {
          setChatMessages(prevTranscript => [...prevTranscript, ...transcriptResponse]);
        }
      });
    });
  }, [chatNotificationAudio, selectedChat, setChatMessages, setChatContacts, setUnreadMessages]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      connect.core.initCCP(containerRef.current, {
        ccpUrl: `${instanceURL}/connect/ccp-v2/`,
        loginPopup: true,
        loginOptions: {
          autoClose: true
        },
        loginUrl: samURL,
        ccpLoadTimeout: 3000,
        region: region,
        softphone: {
          allowFramedSoftphone: true
        },
        chat: {
          ringtoneUrl: `${process.env.REACT_APP_CATO_URL ?? ''}/chatRingtone.mp3`
        },
        pageOptions: {
          enableAudioDeviceSettings: true
        }
      });

      connect.core.onIframeRetriesExhausted(() => {
        console.error('onIframeRetriesExhausted');
      });

      connect.core.onViewContact(contact => {
        onViewContact({ contactId: contact.contactId });
      });

      connect.core.onInitialized(() => {
        setBackdropState(BackdropState.None);

        connect.contact(contact => {
          const initialConnection = contact.getActiveInitialConnection();
          const endpoints = initialConnection?.getEndpoint();
          const phoneNumber = endpoints?.phoneNumber;
          const queueName = contact.getQueue().name;

          onViewContact({
            contactId: contact.contactId,
            groupSubsidiary: queueName,
            phoneNumber: phoneNumber
          });
        });
      });
      connect.core.onAuthFail(function () {
        setBackdropState(BackdropState.AuthenticationExpired);
      });
      connect.core.onAccessDenied(function () {
        setBackdropState(BackdropState.AuthenticationExpired);
      });

      connect.agent(function (agent) {
        setAgent(agent);

        agent.onRefresh(() => {
          setAgentState(agent.getState());
        });
      });

      connect.contact(contact => {
        if (contact.getType() !== connect.ContactType.CHAT) {
          setTelephonyStatusBarOpen(true);

          const handleIncomingCall = (): void => {
            setAgentVoiceConnection(contact.getInitialConnection());
            setIsTelephonyWidgetOpen(true);

            setActivePage(Page.TELEPHONY);
            addRecentCallsToLocalStorage(contact);
          };

          contact.onIncoming(() => {
            handleIncomingCall();
          });

          contact.onConnecting(() => {
            handleIncomingCall();
          });

          contact.onConnected(() => {
            setAgentVoiceConnection(contact.getInitialConnection());
          });

          contact.onRefresh(newContact => {
            setVoiceContact(newContact);
            setThirdPartyVoiceConnection(contact.getSingleActiveThirdPartyConnection() as connect.VoiceConnection);
          });

          contact.onDestroy(() => {
            setVoiceContact(null);
            setAgentVoiceConnection(null);
            setThirdPartyVoiceConnection(null);
            setTelephonyStatusBarOpen(false);
            onViewContact({ contactId: '' });
          });

          return;
        }

        contact.onConnecting(() => {
          setActivePage(Page.CHAT);

          setChatContacts(prevContacts => {
            if (!prevContacts.includes(contact.contactId)) {
              return [...prevContacts, contact.contactId];
            }
            return prevContacts;
          });
        });

        contact.onDestroy(() => {
          setChatContacts(prevContacts => prevContacts.filter(contactId => contactId !== contact.contactId));

          if (chatContacts.length === 0) {
            setSelectedChat(null);
          }
        });
      });
    }

    // Too many deps to list, needs to be refactored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceURL, samURL, onViewContact]);

  return (
    <div style={{ position: 'relative' }}>
      <Header instanceUrl={instanceURL} />
      {telephonyStatusBarOpen && <TelephonyStatusBar />}
      <Box
        sx={{
          display: 'flex',
          background: 'white',
          height: telephonyStatusBarOpen ? 'calc(100vh - 115px)' : 'calc(100vh - 64px)'
        }}
      >
        <SidePanel />
        <PageContainer containerRef={containerRef} />
      </Box>
      <Backdrop sx={{ background: '#F2F7FC', position: 'absolute' }} open={backdropState !== BackdropState.None}>
        {backdropState === BackdropState.Loading ? <CircularProgress /> : ''}
        {backdropState === BackdropState.AuthenticationExpired ? <AuthenticationExpired /> : ''}
      </Backdrop>
    </div>
  );
}

export default CatoContainer;
