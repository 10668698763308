import { Box, Label, Typography } from '@coolblue-development/becky';
import React from 'react';
import { useAppContext } from '../../../../../AppContext';
import AcceptChatButton from './AcceptChatButton';
import EndChatButton from './EndChatButton';
import { fetchContact } from '../../../../../utils/fetchContact';
import TimerNew from './TimerNew';
import LastChatTimerNew from './LastChatTimerNew';
interface ChatTabProps {
  contactId: string;
}

const ChatTab: React.FC<ChatTabProps> = ({ contactId }) => {
  const { setSelectedChat, selectedChat, unreadMessages } = useAppContext();
  const contact = fetchContact(contactId);
  const contactStatus = contact ? contact.getStatus().type : null;
  const hasUnreadMessages = unreadMessages[contactId] > 0;

  const handleClick = (): void => {
    if (selectedChat !== contactId) {
      if (contactStatus === 'connected' || contactStatus === 'ended') {
        setSelectedChat(contactId);
      }

      if (unreadMessages[contactId]) {
        unreadMessages[contactId] = 0;
      }
    }
  };

  const getChatTabStateLabel = (contactStatus: string | null): JSX.Element | null => {
    const statusConfig: Record<string, { color: 'green' | 'orange' | 'red'; text: string }> = {
      connecting: { color: 'green', text: 'Incoming' },
      ended: { color: 'orange', text: 'After Call Work' },
      rejected: { color: 'red', text: 'Missed' },
      missed: { color: 'red', text: 'Missed' },
      error: { color: 'red', text: 'Missed' }
    };

    if (!contactStatus || !statusConfig[contactStatus]) {
      return null;
    }

    const { color, text } = statusConfig[contactStatus];
    return (
      <Label data-testid="chat-tile-label" color={color} variant="subtle">
        {text}
      </Label>
    );
  };

  const getChatTabContent = (contactStatus: string | null, contactId: string): JSX.Element | null => {
    switch (contactStatus) {
      case 'connecting':
        return <AcceptChatButton contactId={contactId} />;
      case 'connected':
        return (
          <>
            <TimerNew contactId={contactId} />
            <LastChatTimerNew contactId={contactId} />
          </>
        );
      case 'ended':
        return <TimerNew contactId={contactId} isAfterCallWork={true} />;
      case 'rejected':
      case 'missed':
      case 'error':
        return <EndChatButton contactId={contactId} />;
      default:
        return null;
    }
  };

  const getBorderStyle = (): string => {
    if (selectedChat === contactId) {
      if (contactStatus === 'ended') {
        return '#FF6600';
      } else if (contactStatus === 'connected') {
        return '#0090E3';
      }
    }

    return '#DDDDDD';
  };

  return (
    <Box
      data-testid="chat-tile"
      sx={{ padding: '8px', border: `1px solid ${getBorderStyle()}`, marginBottom: '8px', borderRadius: '5px' }}
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5"> Customer </Typography>
        {hasUnreadMessages && (
          <Label color="orange" data-testid="unreadMessageCount">
            {unreadMessages[contactId]}
          </Label>
        )}
        {getChatTabStateLabel(contactStatus)}
      </Box>
      <Box sx={{ marginTop: '15px' }}>{getChatTabContent(contactStatus, contactId)}</Box>
    </Box>
  );
};

export default ChatTab;
