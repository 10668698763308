import { Box } from '@coolblue-development/becky';
import { useEffect, useRef } from 'react';
import { useAppContext } from '../../../../../AppContext';
import postEvent from '../../../../../utils/postEvent';
import getCurrentTime from '../../../../../utils/timeFactory';
import ChatTab from '../ContactTab/ChatTab';

const ChatSidePanel = (): JSX.Element => {
  const { selectedChat, chatContacts } = useAppContext();
  const prevContactId = useRef<string | null>(null);

  useEffect(() => {
    const currentTime = getCurrentTime();

    if (prevContactId.current) {
      const previousTimestamp = localStorage.getItem(`contactFocusTimestamp`);

      if (previousTimestamp) {
        void postEvent(prevContactId.current, 'focus', {
          startedAt: new Date(JSON.parse(previousTimestamp) as Date),
          endedAt: currentTime
        });
      }
    }

    localStorage.setItem(`contactFocusTimestamp`, JSON.stringify(currentTime));

    prevContactId.current = selectedChat;
  }, [selectedChat]);

  return (
    <Box>
      {chatContacts?.map(contact => (
        <ChatTab key={contact} contactId={contact} />
      ))}
    </Box>
  );
};

export default ChatSidePanel;
