import { useEffect } from 'react';
import { Box } from '@coolblue-development/becky';
import CallContainer from './CallContainer';
import { TelephonyWidgetContainer as Container } from '../../../../../models/TelephonyWidgetContainer';
import Navigation from './Navigation';
import OutboundCallContainer from './OutboundCallContainer';
import QuickConnectsContainer from './QuickConnectsContainer';
import RecentCallContainer from './RecentCallContainer';
import { useAppContext } from '../../../../../AppContext';
import NumpadContainer from './NumpadContainer';

const TelephonyWidgetContainer = (): JSX.Element => {
  const { activeTelephonyWidgetContainer, setActiveTelephonyWidgetContainer, voiceContact, isTelephonyWidgetOpen } =
    useAppContext();

  useEffect(() => {
    if (voiceContact) {
      setActiveTelephonyWidgetContainer(Container.CALL);
      return;
    }
    setActiveTelephonyWidgetContainer(Container.OUTBOUND);
  }, [setActiveTelephonyWidgetContainer, voiceContact]);

  return (
    <Box
      sx={{
        display: isTelephonyWidgetOpen ? 'flex' : 'none',
        flexDirection: 'column',
        position: 'fixed',
        textAlign: 'center',
        left: '85px',
        bottom: '27px',
        background: '#13171E',
        width: '322px',
        height: '572px',
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 4px 26px 0px rgba(0, 0, 0, 0.4)',
        zIndex: 1
      }}
    >
      {activeTelephonyWidgetContainer !== Container.QUICK_CONNECT &&
        activeTelephonyWidgetContainer !== Container.NUMPAD && <Navigation />}
      {activeTelephonyWidgetContainer === Container.CALL && <CallContainer />}
      {activeTelephonyWidgetContainer === Container.OUTBOUND && <OutboundCallContainer />}
      {activeTelephonyWidgetContainer === Container.RECENT && <RecentCallContainer />}
      {activeTelephonyWidgetContainer === Container.QUICK_CONNECT && <QuickConnectsContainer />}
      {activeTelephonyWidgetContainer === Container.NUMPAD && <NumpadContainer />}
    </Box>
  );
};

export default TelephonyWidgetContainer;
