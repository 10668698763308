import { Box, Typography } from '@coolblue-development/becky';
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../../../AppContext';
import { datadogLogs } from '@datadog/browser-logs';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';

interface QuickRepliesProps {
  setShowReplies: (value: boolean) => void;
  quickReplies: connect.QuickResponse[];
}

const QuickReplies = ({ setShowReplies, quickReplies }: QuickRepliesProps): JSX.Element => {
  const { selectedChat } = useAppContext();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [filteredReplies, setFilteredReplies] = useState<connect.QuickResponse[]>([]);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const extractedReplies = selectedCategory
      ? quickReplies.filter(reply => reply.description === selectedCategory)
      : quickReplies;

    setFilteredReplies(extractedReplies);
  }, [quickReplies, selectedCategory]);

  useEffect(() => {
    const extractedCategories = Array.from(new Set(quickReplies.map(reply => reply.description || '')));
    setCategories(extractedCategories);
  }, [quickReplies]);

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories, quickReplies]);

  const handleReplyClick = (reply: connect.QuickResponse): void => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();

      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(reply.contents.plainText.content);

      paragraph.append(textNode);
      root.append(paragraph);
    });
    setShowReplies(false);

    datadogLogs.logger.info(`Quick Reply clicked`, { reply: reply.name, selectedChat: selectedChat });
  };

  return (
    <Box
      data-testid="quick-replies"
      sx={{
        position: 'absolute',
        bottom: 55,
        border: '1px solid #DDDDDD',
        padding: '15px',
        background: 'white',
        borderRadius: '8px',
        minWidth: '600px',
        boxShadow: '0px 4px 4px 0px #00000040',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        zIndex: 1
      }}
    >
      {filteredReplies.map((reply, index) => (
        <Typography
          data-testid={`quick-reply-${index}`}
          key={index}
          sx={{
            border: '1px solid #DDDDDD',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '4px',
            cursor: 'pointer',
            ':hover': { border: '1px solid #BBBBBB' }
          }}
          onClick={() => handleReplyClick(reply)}
        >
          {reply.contents.plainText.content}
        </Typography>
      ))}
      <Box sx={{ display: 'flex', background: '#F2F7FC', borderRadius: '8px' }}>
        {categories.map((category, index) => (
          <Typography
            key={index}
            sx={{
              color: selectedCategory === category ? '#111111' : '#0090E3',
              borderBottom: selectedCategory === category ? '3px solid #0090E3' : '',
              padding: '10px',
              margin: '0px 10px',
              cursor: 'pointer'
            }}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default QuickReplies;
