import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IconButton } from '@coolblue-development/becky';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { datadogLogs } from '@datadog/browser-logs';

interface MarkdownListButtonProps {
  listType: 'ordered' | 'unordered';
  activeListType: 'ordered' | 'unordered' | null;
  setActiveListType: React.Dispatch<React.SetStateAction<'ordered' | 'unordered' | null>>;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const MarkdownListButton = ({
  listType,
  activeListType,
  setActiveListType,
  Icon
}: MarkdownListButtonProps): JSX.Element => {
  const [editor] = useLexicalComposerContext();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (activeListType === listType) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setActiveListType(null);
    } else {
      editor.dispatchCommand(
        listType === 'ordered' ? INSERT_ORDERED_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND,
        undefined
      );
      setActiveListType(listType);
    }

    datadogLogs.logger.info(`Markdown used:`, { format: listType });
  };

  return (
    <IconButton
      preset="text"
      onClick={onClick}
      iconSize="small"
      sx={{
        width: 'auto',
        height: 'auto',
        padding: '8px',
        ':hover': { background: '#F3F3F3' },
        background: activeListType === listType ? '#F3F3F3' : 'transparent'
      }}
    >
      <Icon />
    </IconButton>
  );
};

export default MarkdownListButton;
