import { Box } from '@coolblue-development/becky';
import CheckmarkIcon from '../../../Icons/CheckMarkIcon';
import CloseChatBubbleIcon from '../../../Icons/CloseChatBubbleIcon';
import CloseConversationButton from './CloseConversationButton';
import { useAppContext } from '../../../../../AppContext';
import { fetchContact } from '../../../../../utils/fetchContact';
import CassieShowButton from '../../../../CassieContainer/CassieShowButton';

const ChatTopPanel = (): JSX.Element => {
  const { selectedChat } = useAppContext();
  const contact = selectedChat ? fetchContact(selectedChat) : null;

  const isACW = contact?.isConnected();
  const icon = isACW ? <CloseChatBubbleIcon /> : <CheckmarkIcon />;
  const label = isACW ? 'Close conversation' : 'Close contact';

  return (
    <Box
      sx={{
        height: '60px',
        borderBottom: '#DDDDDD 1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '15px',
        gap: '10px'
      }}
    >
      <CassieShowButton />
      {<CloseConversationButton icon={icon} label={label} disabled={!contact} />}
    </Box>
  );
};

export default ChatTopPanel;
