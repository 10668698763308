import { Box } from '@coolblue-development/becky';
import ChatTopPanel from '../TopPanel/ChatTopPanel';
import ChatBody from './ChatBody';
import ChatInput from '../InputForm/ChatInput';

const ChatPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        boxShadow: '0px 4px 16px 0px #246CB429',
        borderRadius: '12px'
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <ChatTopPanel />
        <ChatBody />
        <ChatInput />
      </Box>
    </Box>
  );
};

export default ChatPage;
