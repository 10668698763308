import { datadogLogs } from '@datadog/browser-logs';

export async function getChatSession(contact: connect.Contact | null): Promise<connect.AgentChatSession | null> {
  if (!contact) return null;

  const chatConnection = contact
    .getConnections()
    .find(cnn => cnn.getType() === connect.ConnectionType.AGENT && cnn instanceof connect.ChatConnection) as
    | connect.ChatConnection
    | undefined;

  if (!chatConnection) return null;

  try {
    const chatSession = (await chatConnection.getMediaController()) as connect.AgentChatSession;
    return chatSession;
  } catch (error) {
    datadogLogs.logger.error('Failed to fetch chat session');
    return null;
  }
}
