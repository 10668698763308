import { useState } from 'react';
import { Box } from '@coolblue-development/becky';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { ListNode, ListItemNode } from '@lexical/list';
import { EditorEventPlugin } from '../Plugins/EditorEventPlugin';
import QuickRepliesContainer from '../QuickRepliesContainer';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import LinkButton from '../LinkButton';
import { MATCHERS, validateUrl } from '../../../../../../utils/regex';
import { ReactComponent as ItalicIcon } from '../../../../../../assets/italic.svg';
import { ReactComponent as BoldIcon } from '../../../../../../assets/bold.svg';
import { ReactComponent as BulletListIcon } from '../../../../../../assets/bulletList.svg';
import { ReactComponent as NumberedListIcon } from '../../../../../../assets/numberedList.svg';
import MarkdownButton from '../MarkdownButton';
import MarkdownListButton from '../MarkdownListButton';
import SendButton from '../SendButton';
import EmojiContainer from '../EmojiContainer';

const theme = {
  text: {
    bold: 'bold',
    italic: 'italic'
  }
};

const initialConfig = {
  namespace: 'MyEditor',
  theme,
  nodes: [ListNode, ListItemNode, LinkNode, AutoLinkNode],
  onError: () => {
    console.error();
  }
};

const Editor = (): JSX.Element => {
  const [activeListType, setActiveListType] = useState<'ordered' | 'unordered' | null>(null);
  const [format, setFormat] = useState<[boolean, boolean]>([false, false]);
  const [isBold, isItalic] = format;

  return (
    <Box position={'relative'}>
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable className="contentEditable" />}
          placeholder={<Box className="placeholder">Type a message...</Box>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <EditorEventPlugin setFormat={setFormat} onChange={() => {}} setActiveListType={setActiveListType} />
        <AutoFocusPlugin />
        <HistoryPlugin />
        <ListPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
        <ClickableLinkPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
            <QuickRepliesContainer />
            <EmojiContainer />
            <MarkdownButton formatType="bold" isActive={isBold} Icon={BoldIcon} />
            <MarkdownButton formatType="italic" isActive={isItalic} Icon={ItalicIcon} />
            <MarkdownListButton
              listType="unordered"
              activeListType={activeListType}
              setActiveListType={setActiveListType}
              Icon={BulletListIcon}
            />
            <MarkdownListButton
              listType="ordered"
              activeListType={activeListType}
              setActiveListType={setActiveListType}
              Icon={NumberedListIcon}
            />
            <LinkButton />
          </Box>
          <SendButton />
        </Box>
      </LexicalComposer>
    </Box>
  );
};

export default Editor;
